<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col cols="12" md="12">
                        <b-card no-body>
                            <b-card-header>
                                <h4 class="card-title">{{ pageTitle }}</h4>

                            </b-card-header>

                            <b-card-body>
                                <!-- Primary invoice document -->
                                <transition name="fade" >
                                    <b-row
                                        v-if="documentType == 2"
                                        class="border-bottom pb-2">
                                        <b-col cols="12" md="2">
                                            <h5>
                                                {{ i18nT(`Attached invoice document`) }}
                                            </h5>
                                        </b-col>
                                        <b-col cols="12" md="10">
                                            <!-- Dropzone -->
                                            <b-row v-if="document.InvoiceFile == null">
                                                <b-col cols="12" :class="dropzone2HasError ? 'sk-error' : ''">
                                                    <vue-dropzone ref="mainDropzone"
                                                                  :duplicateCheck="true"
                                                                  @vdropzone-queue-complete="queComplete"
                                                                  @vdropzone-error="dropzoneError"
                                                                  @vdropzone-added="onFileAdded"
                                                                  @vdropzone-success="dropzoneSuccess"
                                                                  @vdropzone-sending="dropzoneAddParams"
                                                                  id="dz-invoice"
                                                                  class="sk-dropzone"
                                                                  :options="dropzoneOptions"></vue-dropzone>
                                                    <div class="mt-2">
                                                        <span class="text-muted">{{i18nT(`Here you can attach a scanned copy of the original document. The system will attempt to automatically recognize the data in the document and to populate the form below.`)}}</span>
                                                    </div>
                                                </b-col>
                                            </b-row>

                                            <b-row v-if="document.InvoiceFile && document.InvoiceFile.Id">
                                                <b-col cols="12">
                                                    <b-list-group-item
                                                        tag="li"
                                                    >
                                                        <b-row>
                                                            <b-col cols="4" class="d-flex align-items-center">
                                                                <b-link :href="document.InvoiceFile.DownloadUrl" target="_blank" :download="document.InvoiceFile.Label"><b>{{ document.InvoiceFile.Label }}</b></b-link>
                                                            </b-col>
                                                            <b-col cols="6" class="d-flex align-items-center align-content-center pt-1">
                                                                <b-form-checkbox
                                                                    name="check-button"
                                                                    switch
                                                                    v-model="dropzonePopulateFromResp"
                                                                >
                                                                    {{ i18nT(`Use uploaded invoice data`) }}
                                                                </b-form-checkbox>
                                                            </b-col>
                                                            <b-col cols="2" class="d-flex align-items-center justify-content-end">
                                                                <b-button
                                                                    variant="danger"
                                                                    @click="removePrimaryDocument()"
                                                                >
                                                                    <feather-icon icon="Trash2Icon" size="16"></feather-icon>
                                                                </b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </b-list-group-item>
                                                </b-col>
                                            </b-row>

                                        </b-col>
                                    </b-row>
                                </transition>



                                <!-- Invoice information -->
                                <b-row class="border-bottom pt-2 pb-2" >
                                    <!-- Field: Username -->
                                    <b-col cols="12" md="2">
                                        <h5>
                                            {{ i18nT(`Payment information`) }}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="10">
                                        <b-row class="pb-1">
                                            <b-col cols="6">
                                                <b-form-group
                                                    :label="i18nT(`Category`)"
                                                    label-for="Category"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Category`)"
                                                        rules="required"
                                                    >
                                                        <b-form-select
                                                            v-model="documentCategory"
                                                            :options="paymentCategoryList"
                                                            label="label"
                                                            @change="onCategoryChange"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                    <span class="text-muted font-small-3">
                                                                    {{i18nT(`You can define payment categories in`)}}
                                                                </span>
                                                                <b-link target="_blank" :to="'/ac/settings/payment-categories'" class="font-small-3"> {{i18nT(`Settings`)}}</b-link>
                                                </b-form-group>
                                            </b-col>

                                            <b-col v-if="documentCategory && documentType == 1" cols="4" class="pt-2">
                                                <b-form-checkbox
                                                    class="pt-1"
                                                    name="taxExempt"
                                                    switch
                                                    v-model="categoryNonTaxable"
                                                    :disabled="isTaxable"
                                                >
                                                    {{ i18nT(`Exempt from taxes`) }}
                                                </b-form-checkbox>
                                            </b-col>

                                        </b-row>

                                        <!-- VENDOR -->
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Document number`)"
                                                    label-for="Number"
                                                >
                                                    <b-form-input
                                                        v-model="document.InvoiceNumber"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                          <b-col cols="12" md="6">
                                            <validation-provider #default="{ errors }"
                                                                 :name="i18nT(`Vendor`)"
                                                                 rules="required">
                                              <b-form-group
                                                  :label="documentType == 1 ? i18nT(`Customer`) : i18nT(`Supplier`)"
                                                  label-for="Vendor" class="required">
                                                <div class="d-flex">
                                                  <b-input-group-prepend>
                                                    <b-button variant="primary"
                                                              v-b-tooltip="i18nT(`Add new vendor`)"
                                                              v-b-modal.add-vendor class="corners-straight-right">
                                                      <font-awesome-icon :icon="['fas', 'plus']" />
                                                    </b-button>
                                                  </b-input-group-prepend>

                                                  <b-form-select class="corners-straight-left"
                                                                 v-model="document.VendorId" :options="vendorList" />
                                                  <small class="text-danger">{{ errors[0] }}</small>

                                                </div>
                                                <span class="text-muted font-small-3">
                                                            {{
                                                    i18nT(`Select the provider from the list or click on the PLUS icon to create a new one.`) }}
                                                        </span>

                                              </b-form-group>
                                            </validation-provider>
                                          </b-col>

<!--                                            <b-col cols="12" md="6" >-->
<!--                                                <b-form-group-->
<!--                                                    :label="documentType == 1 ? i18nT(`Customer`) : i18nT(`Supplier`)"-->
<!--                                                    label-for="Vendor"-->
<!--                                                    class="required"-->
<!--                                                >-->
<!--                                                    <validation-provider-->
<!--                                                        #default="{ errors }"-->
<!--                                                        :name="i18nT(`Vendor`)"-->
<!--                                                        rules="required"-->
<!--                                                    >-->
<!--                                                        <b-form-select-->
<!--                                                            class="corners-straight-right input-with-semi-button"-->
<!--                                                            v-model="document.VendorId"-->
<!--                                                            :options="vendorList"-->
<!--                                                        />-->

<!--                                                        <div class="semi-button cursor-pointer d-inline-flex position-absolute top"-->
<!--                                                             v-b-tooltip="i18nT(`Add new vendor`)"-->
<!--                                                            v-b-modal.add-vendor-->
<!--                                                        >-->
<!--                                                            <font-awesome-icon-->
<!--                                                                class="d-block text-white ml-2"-->
<!--                                                                :icon="['fa', 'plus']"></font-awesome-icon>-->
<!--                                                        </div>-->
<!--                                                        <small class="text-danger">{{ errors[0] }}</small>-->
<!--                                                    </validation-provider>-->
<!--                                                    <span class="text-muted font-small-3">-->
<!--                                                            {{-->
<!--                                                                i18nT(`Select the provider from the list or click on the PLUS icon to create a new one.`) }}-->
<!--                                                        </span>-->
<!--                                                </b-form-group>-->
<!--                                            </b-col>-->

                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Currency`)"
                                                    label-for="Vendor"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Currency`)"
                                                        rules="required"
                                                    >
                                                        <b-form-select
                                                            v-model="document.Currency"
                                                            :options="currencyList"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Payment method`)"
                                                    label-for="PaymentMethod"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Payment method`)"
                                                        rules="required"
                                                    >
                                                        <b-form-select
                                                            v-model="document.PaymentType"
                                                            :options="paymentTypeList"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <!-- VENDOR -->
                                        <b-row v-if="document.PaymentType == 1">
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Bank account`)"
                                                    label-for="billingAccount"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Bank account`)"
                                                        rules="required"
                                                    >
                                                        <b-form-select
                                                            v-model="documentBillingAccountId"
                                                            :options="ibansList"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Bank payment details`)"
                                                    label-for="billingDetails"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-default"
                                                        rows="3"
                                                        v-model="documentBillingDetails"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>


                                        <!-- INVOICE DATES -->
                                        <b-row class="border-bottom pb-2 mb-1">
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Document Date`)"
                                                    label-for="invoiceDate"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Document Date`)"
                                                        rules="required"
                                                    >
                                                        <date-picker
                                                            id="invoice-datepicker"
                                                            v-model="document.InvoiceDate"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6" v-if="documentType == 1">
                                                <b-form-group
                                                    :label="i18nT(`Due Date`)"
                                                    label-for="dueDate"
                                                >
                                                    <date-picker
                                                        id="due-datepicker"
                                                        v-model="document.DueDate"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                        </b-row>


                                        <!-- Expense - Team Expense -->
                                        <b-row v-if="documentType == '2'">
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Team expense`)"
                                                    label-for="invoiceDate"
                                                >
                                                    <v-select
                                                        v-model="document.invoice_teams"
                                                        :options="employeeGroups"
                                                        label="text"
                                                        multiple
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Employee expense`)"
                                                    label-for="dueDate"
                                                >
                                                    <v-select
                                                        v-model="document.invoice_employees"
                                                        :options="employees"
                                                        label="text"
                                                        multiple
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>


                                        <!-- Recurrence for invoice (income doc)  -->
                                        <b-row class="pt-1 border-bottom pb-1" v-if="document.Type == 1">
                                            <b-col cols="12" md="12">
                                                <b-form-checkbox
                                                    name="check-button"
                                                    switch
                                                    v-model="document.Repeating"
                                                >
                                                    {{ i18nT(`Enable recurrence`) }}
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-row v-if="document.Repeating">
                                                    <b-col cols="6">
                                                        <b-form-group
                                                            :label="i18nT(`Repeat`)"
                                                            label-for="TaskRepeated"
                                                        >
                                                            <b-form-select
                                                                v-model="document.RepeatFor"
                                                                :options="repeatOptions"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col
                                                        v-if="document.RepeatFor == '2'"
                                                        cols="6"
                                                    >
                                                        <b-form-group
                                                            :label="i18nT(`Weekly schedule`)"
                                                            label-for="tags"
                                                        >
                                                            <v-select
                                                                v-model="RepeatWorkdays"
                                                                multiple
                                                                label="title"
                                                                :options="repeatWeeklyOptions"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col
                                                        v-if="document.RepeatFor == '3'"
                                                        cols="3"
                                                    >
                                                        <b-form-group
                                                            :label="i18nT(`Day`)"
                                                            label-for="tags"
                                                        >
                                                            <v-select
                                                                v-model="RepeatMonthDays"
                                                                label="title"
                                                                :options="repeatDaysOfMonths"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col
                                                        v-if="document.RepeatFor == '3'"
                                                        cols="3"
                                                    >
                                                        <b-form-group
                                                            :label="i18nT(`Monthly schedule`)"
                                                            label-for="tags"
                                                        >
                                                            <v-select
                                                                v-model="RepeatMonths"
                                                                multiple
                                                                label="title"
                                                                :options="repeatMonthlyOptions"
                                                            />
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row v-else>
                                                    <b-alert
                                                        class="mt-1"
                                                        variant="primary"
                                                        show
                                                    >
                                                        <div class="alert-body">
                                                            <span>
                                                              <b-badge variant="primary">
                                                                {{ i18nT(`Info`) }}
                                                              </b-badge>
                                                              {{ i18nT(`You can create recurring expenses / income records that will be automatically generated after a specific period.`) }}
                                                            </span>
                                                        </div>
                                                    </b-alert>
                                                </b-row>
                                            </b-col>

                                        </b-row>


                                        <b-row class="pt-1">

                                        </b-row>
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Internal note`)"
                                                    label-for="internalNote"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-default"
                                                        rows="3"
                                                        v-model="document.InternalComments"
                                                    />
                                                    <span class="text-muted">{{i18nT(`Internal notes are confidential and are not visible to your client.`)}}</span>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>

                                <!-- Payment Items -->
                                <b-row class="border-bottom">
                                    <b-col cols="12" md="2">
                                        <h5 class="mt-2">
                                            {{ i18nT(`Payment Items`) }}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="10">
                                        <div
                                            v-for="(item, index) in paymentItems"
                                            :key="index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="4"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Item description`)"
                                                        label-for="discountNet"
                                                    >
                                                        <b-form-input 
                                                            v-if="documentType === 2"
                                                            v-model="item.Label"
                                                            :placeholder="i18nT(`Product or service delivered`)"
                                                        />

                                                        <div v-else>
                                                            <vue-bootstrap-typeahead v-model="item.Label"
                                                            :data="suggestionsList" :serializer="returnText"
                                                            @hit="selectedOption => updateItemProperties(selectedOption, index)" />


                                                            <span v-if="index == 0" class="text-muted font-small-3">{{
                                                                i18nT(`You can predefine items, products, and services in`) }}
                                                                <b-link target="_blank" :to="'/ac/settings/invoice-items'">{{
                                                                    i18nT(`Settings`) }}</b-link></span>
                                                        </div>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Unit price`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            v-model="item.UnitPrice"
                                                            placeholder="0.00"
                                                            type="number"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Qty`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            v-model="item.Quantity"
                                                            placeholder="1"
                                                            type="number"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                    v-if="false"
                                                >

                                                    <b-form-group
                                                        :label="i18nT(`Discount`)"
                                                        label-for="discount"
                                                    >
                                                        <b-form-input
                                                            :value="itemDiscount(index) | money"
                                                            placeholder="1"
                                                            type="number"
                                                            readonly
                                                        />
                                                    </b-form-group>

                                                </b-col>

                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`VAT (%)`)"
                                                        label-for="VatPercent"
                                                    >
                                                        <b-form-input
                                                            v-model="item.VatPercent"

                                                            type="number"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Subtotal`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :value="itemSubTotal(item, index) | money"
                                                            placeholder="0.00"
                                                            type="number"
                                                            readonly
                                                        />
                                                    </b-form-group>
                                                </b-col>


                                            </b-row>

                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="10"
                                                >
                                                </b-col>


                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Total`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :value="item.Total | money"
                                                            disabled
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeItem(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon"/>
                                                    {{ i18nT(`Remove`) }}
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addItems"
                                            >
                                                <feather-icon icon="PlusCircleIcon"/>
                                                {{ i18nT(`Add more`) }}
                                            </b-button>
                                        </div>

                                        <!-- DISCOUNT TOGGLE -->
                                        <div class="border-bottom pb-1 pt-1" v-if="false">
                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="4"
                                                    md-offset="8"
                                                >

                                                    <b-form-group
                                                        :label="i18nT(`Discount amount`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <div class="d-flex">
                                                            <b-form-input
                                                                class="corners-straight-right"
                                                                :placeholder="i18nT(`0`)"
                                                                v-model="discountValue"
                                                                type="number"
                                                            />
                                                            <div class="semi-button cursor-pointer"
                                                                 v-b-tooltip="i18nT(`Toggle discount mode`)"
                                                                 @click="toggleDiscountMode()">
                                                                <font-awesome-icon
                                                                    class="d-block text-white ml-2"
                                                                    :icon="discountMode == 'percent' ? ['fa', 'percent'] : ['fa', 'dollar-sign']"></font-awesome-icon>
                                                            </div>
                                                        </div>
                                                    </b-form-group>

                                                </b-col>
                                            </b-row>
                                        </div>

                                        <!-- SUMMARIES -->
                                        <div>
                                            <b-row class="mt-1">
                                                <b-col cols="2">
                                                    {{ i18nT(`Items total`) }}
                                                </b-col>
                                                <b-col cols="10" class="pl-2">
                                                    <strong>{{ {amount: subtotalAll, currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1" v-if="discountValue && discountValue > 0">
                                                <b-col cols="2">
                                                    {{ i18nT(`Discount`) }}
                                                </b-col>
                                                <b-col cols="10">
                                                    <strong class="text-danger">- {{  {amount: discountAmount || 0, currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1">
                                                <b-col cols="2">
                                                    {{ i18nT(`Subtotal`) }}
                                                </b-col>
                                                <b-col cols="10" class="pl-2">
                                                    <strong>{{ {amount: (subtotalAll-discountAmount), currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1">
                                                <b-col cols="2">
                                                    {{ i18nT(`VAT or Tax`) }}
                                                </b-col>
                                                <b-col cols="10" class="pl-2">
                                                    <strong>{{ {amount:totalVAT, currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row>
                                                <b-col cols="6">
                                                    <b-row class="mt-1 mb-1 pt-1 border-top">
                                                        <b-col cols="4">
                                                            <h4>{{ i18nT(`Total`) }}</h4>
                                                        </b-col>
                                                        <b-col cols="8" class="pl-2">
                                                            <h4>
                                                                <strong>{{ {amount: finalTotal || 0, currency: document.Currency} | currency}}</strong>
                                                            </h4>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>

                                        </div>
                                    </b-col>
                                </b-row>

                                <!--Additional documents DROPZONE-->
                                <b-row class="border-bottom pt-2 pb-2">
                                    <b-col cols="12" md="2">
                                        <h5>
                                            {{ i18nT(`Additional documents`) }}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="10">
                                        <b-row>
                                            <b-col cols="12" md="12" :class="dropzone2HasError ? 'sk-error' : ''">
                                                <vue-dropzone ref="additionalDropzone"
                                                              :duplicateCheck="true"
                                                              @vdropzone-queue-complete="queCompleteMultiple"
                                                              @vdropzone-error="dropzoneErrorMultiple"
                                                              @vdropzone-added="onFileAdded"
                                                              @vdropzone-success="dropzoneSuccessMultiple"
                                                              id="dz-invoice-multiple"
                                                              class="sk-dropzone"
                                                              :options="dropzone2Options"></vue-dropzone>

                                                <div class="mt-2">
                                                    <b-list-group-item
                                                        v-for="(attachedDoc, index) in document.InvoiceDocuments"
                                                        :key="attachedDoc.Id"
                                                        tag="li"
                                                    >
                                                        <b-row>
                                                            <b-col cols="6" class="d-flex align-items-center">
                                                                <b-link :href="attachedDoc.AttachedFile.DownloadUrl" target="_blank" :download="attachedDoc.Label"><b>{{ attachedDoc.Label }}</b></b-link>
                                                            </b-col>
                                                            <b-col cols="4">
                                                                <label class="text-muted">{{ i18nT(`Document type`) }}</label>
                                                                <b-form-select
                                                                    v-model="document.InvoiceDocuments[index].Type"
                                                                    :options="paymentCategoryList">

                                                                </b-form-select>
                                                            </b-col>
                                                            <b-col cols="2" class="d-flex align-items-center justify-content-end">
                                                                <b-button
                                                                    variant="danger"
                                                                    @click="removeAttachedDocument(index)"
                                                                >
                                                                    <feather-icon icon="Trash2Icon" size="16"></feather-icon>
                                                                </b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </b-list-group-item>
                                                </div>

                                            </b-col>
                                        </b-row>

                                    </b-col>

                                </b-row>

                                <b-row>
                                    <div style="flex: 1"/>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{ i18nT(`Save`) }}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>


        <b-modal id="add-vendor"
                    :title="i18nT(`Add new vendor`)"
                    size="xl"
                    hide-footer
                    centered
                    :no-close-on-backdrop="true"
                    :no-close-on-esc="true"
        >
            <edit-vendor @vendorAdded="onVendorAdded" :modalMode="true" />
        </b-modal>


    </div>

</template>

<script>
import {
  BButton,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BCardBody,
  BFormSelect,
  BListGroupItem,
  BLink,
  VBModal,
  VBTooltip,
  // BTabs,
  // BTab,
  // BFormFile,
  // BInputGroup,
  // BInputGroupText,
  BCardHeader,
  BInputGroupPrepend,
  // BDropdown,
  // BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {dictToSelectArray, dictToSelectArrayWithTitle} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth';
import _ from 'lodash'
import {getCurrentInstance} from '@vue/composition-api'
import DatePicker from '@core/components/DatePicker.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {API_KEY} from "@core/utils/constants";
import EditVendor from "@/views/accounting/Vendors/EditVendor.vue";
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap'


export default {
    components: {
        BInputGroupPrepend,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        VueBootstrapTypeahead,
        BCard,
        BCardBody,
        vSelect,
        BFormTextarea,
        BFormSelect,
        BAlert,
        BBadge,
        BFormCheckbox,
        BListGroupItem,
        BLink,
        // BTabs,
        // BTab,
        ValidationProvider,
        ValidationObserver,
        // BFormFile,
        // BInputGroup,
        // BInputGroupText,
        BCardHeader,
        // BDropdown,
        // BDropdownItem,
        VueDropzone: vue2Dropzone,
        DatePicker,
        EditVendor
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    data() {
        return {

            document: {
                Discount: 0,
                IsInvoice: 0,
                IsRecurring: 0,
                Repeating: false,
                Type: 1,
                RecordType: 1,
                Category: 1,
                InvoiceDocuments: [],
                InvoiceFile: null,
            },
            documentCategory: null,
            documentType: 0,
            lastInvoiceNumber: '',
            documentBillingAccountId: -1,
            documentBillingDetails: '',
            categoryNonTaxable: false,

            discountMode: 'percent',
            discountValue: 0,
            discountAmount: 0,
            discounts:[],
            totalVAT: 0,
            suggestionsList: [],
            tempInvoice: null,
            originalInvoice: null,
            file: null,
            fileName: null,
            required,
            email,
            hasUrl: false,
            hasFile: false,
            originalFileName: null,
            vendorList: [],
            typeList: [],
            ibansList: [],
            ibansFull: [],
            paymentCategoryList: [],
            paymentTypeList: [],
            currencyList: [],
            recordTypeList: [],
            documentTypes: [],
            paymentItems: [{
                Total: 0,
                VatPercent: 0
            }],
            employeeGroups: [],
            employees: [],
            additionalDocs: [
                {
                    Name: '',
                    Url: '',
                },
            ],

            dropzoneInvoiceFile: null,
            dropzonePopulateFromResp: true,
            dropzone1HasError: false,
            dropzone2HasError: false,
            dropzoneOptions: {
                url: process.env.VUE_APP_API_URL+'invoices/invoiceFile',
                paramName: 'invoiceDocument',
                addRemoveLinks: true,
                autoProcessQueue: true,
                clickable: true,
                dictDefaultMessage: this.i18nT(`Drag and drop the invoice file here (PDF up to 5.0 MB)`),
                dictRemoveFile: this.i18nT(`Remove`),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.i18nT(`File is too big ({{filesize}}MB). Max filesize is: {{maxFilesize}}MB`),
                dictInvalidFileType: this.i18nT(`Only PDFs are allowed`),
                dictCancelUpload: this.i18nT(`Cancel`),
                dictCancelUploadConfirmation: this.i18nT(`Are you sure you want to cancel this upload?`),
                dictMaxFilesExceeded: this.i18nT(`You can only upload 1 file here`),
                maxFilesize: 5,
                maxFiles: 1,
                acceptedFiles: "application/pdf",
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },
            dropzone2Options: {
                url: process.env.VUE_APP_API_URL+'invoices/invoiceAttachments',
                addRemoveLinks: true,
                autoProcessQueue: true,
                clickable: true,
                dictDefaultMessage: this.i18nT(`Drag and drop additional files here`),
                dictRemoveFile: this.i18nT(`Remove`),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.i18nT(`File is too big ({{filesize}}MB). Max filesize is: {{maxFilesize}}MB`),
                dictInvalidFileType: this.i18nT(`Only PDFs and JPGs are allowed`),
                dictCancelUpload: this.i18nT(`Cancel`),
                dictCancelUploadConfirmation: this.i18nT(`Are you sure you want to cancel this upload?`),
                dictMaxFilesExceeded: this.i18nT(`You can attach up to 10 files`),
                maxFilesize: 5,
                maxFiles: 10,
                acceptedFiles: 'image/*,application/pdf',
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },

            //Repeat options
            repeatOptions: [],
            repeatWeeklyOptions: [],
            repeatMonthlyOptions: [],
            repeatDaysOfMonths: [],

            RepeatWorkdays: [],
            RepeatMonthDays: [],
            RepeatMonths: [],
        }
    },
    created() {
        this.document.Template = router.currentRoute.meta.template === 1
        this.softwares = _.values(useAuth.getSoftwares()).map(software => ({
            value: software.id,
            title: `${software.short.toUpperCase()} - ${software.title}`
        }))
        Promise.all([
            new Promise((res) => {
                this.$http.get(
                    `vendors/vendorsList`
                ).then(({data}) => {
                    this.vendorList = dictToSelectArray(data.data)
                    res()
                })
            }),

            new Promise((res) => {
                this.$http.get(
                    `invoices/lastInvoiceNumber`
                ).then(({data}) => {
                    this.lastInvoiceNumber = data.data
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `settings/invoiceItemSuggests`
                ).then(({ data }) => {
                    this.suggestionsList = data.data.map(suggestion => ({
                        value: suggestion.Id,
                        text: suggestion.Label,
                        unitPrice: suggestion.Price,
                        vatPercent: suggestion.Vat,
                        fullData: suggestion
                    }))
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `system/financeTypes`
                ).then(({data}) => {
                    this.typeList = dictToSelectArray(data.data)
                    res()
                })
            }),

            new Promise((res) => {
                this.$http.get(
                    `system/paymentTypes`
                ).then(({data}) => {
                    this.paymentTypeList = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `invoices/invoiceBankaccounts`
                ).then(({data}) => {
                    this.ibansFull = data.data.bank_accounts;
                    this.ibansList = data.data.bank_accounts.map(iban => ({
                        value: iban.Id,
                        text: iban.Label +(iban.Iban ? ` (${iban.Iban})` : '')
                    }))
                    this.ibansList.unshift(
                        {
                            value: -1,
                            text: this.i18nT(`Other`)
                        }
                    )
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `system/currencies`
                ).then(({data}) => {
                    this.currencyList = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `documents/documentTypes`
                ).then(({data}) => {
                    this.documentTypes = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `leaves/employeesFilter`
                ).then(({data}) => {
                    this.employees = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`invoices/repeatOptions`).then(({data}) => {
                    this.repeatOptions = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`invoices/repeatWeekly`).then(({data}) => {
                    this.repeatWeeklyOptions = dictToSelectArrayWithTitle(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`invoices/repeatMonthly`).then(({data}) => {
                    this.repeatMonthlyOptions = dictToSelectArrayWithTitle(data.data)
                    //let everyMonth = {text: data.data[12].text, value: data.data[12].value};
                    //this.repeatMonthlyOptions.splice(12, 1);
                    //this.repeatMonthlyOptions.unshift(everyMonth);
                    //console.log("Monthly options:", this.repeatMonthlyOptions);
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`invoices/daysOfMonth`).then(({data}) => {
                    this.repeatDaysOfMonths = dictToSelectArrayWithTitle(data.data)
                    res()
                })
            }),

            new Promise((res) => {
                this.$http.get(
                    `employees/employeeGroups?show_per_page=100`
                ).then(({data}) => {
                    this.employeeGroups = data.data.employee_groups.map(employeeGroup => ({
                        value: employeeGroup.group.Id,
                        text: employeeGroup.group.Label
                    }))
                    res()
                })
            }),
        ]).then(() => {
            console.log("PaymentCategoryList: ", this.paymentCategoryList)
            if (router.currentRoute.params.id) {
                this.dropzonePopulateFromResp = false;
                this.$http.get(
                    `invoices?id=${router.currentRoute.params.id}`
                ).then(({data}) => {
                    this.postProcessInvoice(data.data, true);
                })
            }
        })

        if(this.$route.name == 'create-expense' || this.$route.name == 'edit-expense'){
            this.documentType = 2;
        } else {
            this.documentType = 1;
        }
    },
    computed: {
        isTaxable() {
            if(this.documentCategory){

              const documentCat = this.paymentCategoryList.find(category => category.value === this.documentCategory)
              if(documentCat){
                return !documentCat.fullData.NonTaxable;
              } else{
                return false;
              }
            }else {
                return false;
            }
        },

        hasRouteId() {
            return router && router.currentRoute.params.id
        },
        finalSubtotal() {
          return this.subtotalAll - (this.discountValue || 0);
        },

        totalAll(){
            return this.paymentItems.filter(item => !!item.UnitPrice && !!item.Quantity).reduce((acc, item) =>
                    (acc + (item.UnitPrice * item.Quantity * ((item.VatPercent ? item.VatPercent : 0) / 100 + 1))),
                0).toFixed(2) - (this.discountValue || 0)
        },
        subtotalAll(){
            return this.paymentItems.filter(item => !!item.UnitPrice && !!item.Quantity).reduce((acc, item) =>
                    (acc + item.UnitPrice * item.Quantity),
                0).toFixed(2);
        },
        finalTotal() {
            return parseFloat(this.subtotalAll - this.discountAmount) + parseFloat(this.totalVAT);
        },
        pageTitle() {
          let pageTitle = 'Edit income';

          switch( this.$route.name) {
                case 'create-expense':
                    pageTitle = this.i18nT(`Add expense`);
                    break;
                case 'edit-expense':
                    pageTitle = this.i18nT(`Edit Expense`);
                    break;
                case 'create-income':
                    pageTitle = this.i18nT(`Add income`);
                    break;
                case 'edit-income':
                    pageTitle = this.i18nT(`Edit income`);
                    break;
          }

          return pageTitle;

        },

    },
    methods: {
        updateItemProperties(selectedOption, index) {
            if (selectedOption && selectedOption.unitPrice && selectedOption.vatPercent) {
                //this.$set(this.paymentItems[index], 'Label', selectedOption.text);
                this.$set(this.paymentItems[index], 'UnitPrice', selectedOption.unitPrice);
                this.$set(this.paymentItems[index], 'VatPercent', selectedOption.vatPercent);
                this.$set(this.paymentItems[index], 'Quantity', this.paymentItems[index].Quantity ? this.paymentItems[index].Quantity : 1);
            }
        },
        returnText(s) {
            return s.text;
        },
        onVendorAdded() {
            this.$bvModal.hide('add-vendor');
            this.$http.get(
                `vendors/vendorsList`
            ).then(({data}) => {
                this.vendorList = dictToSelectArray(data.data)
                this.document.VendorId = this.vendorList[this.vendorList.length - 1].value;
            });
        },
        onCategoryChange(item) {
          console.log('item:', item)
            let fullRecord = this.paymentCategoryList.find(category => category.value == item).fullData;
            this.document.RecordType = fullRecord.RecordType;
            this.document.Taxable = !fullRecord.NonTaxable;
        },
        updateTotalVat(){
            let total = 0;
            let self = this;

            if(this.paymentItems.length !== 0) {
                for (let i = 0; i < self.paymentItems.length; i++) {
                    total += (!!self.paymentItems[i].Quantity && !!self.paymentItems[i].UnitPrice) ?
                        ( (self.paymentItems[i].Quantity * self.paymentItems[i].UnitPrice)  - self.itemDiscount(i) )* (self.paymentItems[i].VatPercent / 100).toFixed(2) : 0;
                }
            }
            this.totalVAT = total;
        },
        updateTotals(itemList){
            let self = this;
            for (let i = 0; i < itemList.length; i++) {
                itemList[i].Total = (!!itemList[i].Quantity && !!itemList[i].UnitPrice) ?
                    ( (itemList[i].Quantity * itemList[i].UnitPrice)  - self.itemDiscount(i) )* (1 + (itemList[i].VatPercent ?? 0) / 100).toFixed(2)  + ""
                    : 0
            }
            return itemList;
        },
        itemSubTotal(item, index) {
            if(!index){
               index = this.paymentItems.indexOf(item);
            }
            return item.Quantity * item.UnitPrice - this.itemDiscount(index)
        },
        itemDiscount(index){
           return this.discounts[index] ? this.discounts[index] : 0;
        },
        calcActualDiscount() {
            let self = this;

            if(this.discountMode == 'percent') {
                this.discountAmount = this.subtotalAll * this.discountValue / 100;
            }else {
                this.discountAmount = this.discountValue;
            }

            self.paymentItems.forEach(function (item, index) {
                let proportion = item.UnitPrice * item.Quantity / self.subtotalAll;
                self.discounts[index] = self.discountAmount * proportion;
            });

            return this.discountAmount
        },
        toggleDiscountMode() {
            this.discountMode = this.discountMode == 'percent' ? 'amount' : 'percent';
            this.calcActualDiscount();
        },
        dropzoneAddParams(file, xhr, formData) {
            let isClient = this.document.Type === 2 ? 1 : 0;
            formData.append('isClient', isClient);
            this.$store.commit('app/REQUEST_PENDING')
        },

        removeAttachedDocument(index) {
            this.document.InvoiceDocuments.splice(index, 1)
        },
        removePrimaryDocument(){
            this.document.InvoiceFile = null;
            this.tempInvoice = null;
        },
        postProcessInvoice(invoiceData, initialLoad  = false){
            this.document = invoiceData;

            this.paymentItems = [];
            this.paymentItems = this.document.InvoiceItems

            if (this.document.RepeatFor == 2) {
                const repeatDays = (this.document.RepeatData + '' || '').split(',')
                repeatDays.forEach(repeatDay => {
                    this.RepeatWorkdays.push(
                        this.repeatWeeklyOptions[parseInt(repeatDay) - 1]
                    )
                })
            }
            else if (this.document.RepeatFor == 3) {
                if (this.document.DayOfMonth == 99) {
                    this.RepeatMonthDays = this.repeatDaysOfMonths[
                    this.repeatDaysOfMonths.length - 1
                        ]
                } else {
                    this.RepeatMonthDays = this.repeatDaysOfMonths[
                    this.document.DayOfMonth - 1
                        ]
                }

                const repeatMonths = (this.document.RepeatData + '' || '').split(',')
                repeatMonths.forEach(repeatMonth => {
                    this.RepeatMonths.push(
                        this.repeatMonthlyOptions[parseInt(repeatMonth) - 1]
                    )
                })
            }

            if(this.document.DiscountPercent){
                this.discountMode = 'percent';
                this.discountValue = this.document.DiscountPercent;
            } else {
                this.discountMode = 'amount';
                this.discountValue = this.document.DiscountAmount;
            }

            if(this.document.Category){
                this.documentCategory = this.document.Category;
            }

            if(this.document.BillingAccountId){
                this.documentBillingAccountId = this.document.BillingAccountId;
            }

            if(this.document.BillingDetails){
                this.documentBillingDetails = this.document.BillingDetails;
            }

            if(this.document.NonTaxable){
                this.categoryNonTaxable = this.document.NonTaxable ? true : false;
            }

            if(!initialLoad){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT('Invoice data updated from attachment'),
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                })
            }
        },
        dropzoneSuccessMultiple: function (files, response) {
            this.document.InvoiceDocuments.push({...response.data, AttachedFile: {DownloadUrl: response.data.DownloadUrl}});
        },

        dropzoneSuccess(file, response) {
            this.$store.commit('app/REQUEST_DONE')
            if(response.data.InvoiceFile){
                this.$http.get(
                    `vendors/vendorsList`
                ).then(({data}) => {
                    this.vendorList = dictToSelectArray(data.data)
                    this.document.InvoiceFile =  response.data.InvoiceFile;
                    this.tempInvoice = response.data;
                    if(this.dropzonePopulateFromResp){
                        this.postProcessInvoice(response.data);
                    }
                });
            }

        },
        dropzoneError (message) {

            let self = this;
            this.$store.commit('app/REQUEST_DONE')

            setTimeout(function () {
                self.$refs.mainDropzone.removeAllFiles();
                self.dropzone1HasError = false;
            }, 6000);

            if(message.status === 'error' && !message.accepted){
                self.dropzone1HasError = true;
                self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: self.i18nT(message.previewElement.childNodes[7].innerText),
                        icon: 'InfoIcon',
                        variant: 'danger',
                    },
                })
            }else if (message.status === 'error' && message.accepted) {

                let resp = JSON.parse(message.xhr.response);
                console.log("API ERROR: ", resp)
                if(resp && resp.message)
                self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: resp.message,
                        icon: 'InfoIcon',
                        variant: 'danger',
                    },
                })
                //
                // this.$toast({
                //     component: ToastificationContent,
                //     position: 'top-right',
                //     props: {
                //         title: self.i18nT(`Invoice processed successfully`),
                //         icon: 'InfoIcon',
                //         variant: 'success',
                //     },
                // })
            }
        },
        dropzoneErrorMultiple(message){
            this.dropzone2HasError = true;
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: 9000,
                props: {
                    title: this.i18nT(message.previewElement.childNodes[7].innerText),
                    text: message.upload.filename,
                    icon: 'InfoIcon',
                    variant: 'danger',
                    timeout: 20000,
                },
            })
        },
        onFileAdded(file) {
            console.log("File added: ", file);
            //this.$refs.myVueDropzone.processQueue();
        },
        queComplete() {
            this.$refs.mainDropzone.removeAllFiles();
            //this.$refs.myVueDropzone.removeAllFiles();
        },

        queCompleteMultiple() {
            let self = this;

            setTimeout(function () {
                self.$refs.additionalDropzone.removeAllFiles();
                self.dropzone2HasError = false;
            }, 4000);
        },

        onFilePick: function () {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileDelete: function () {
            this.file = null
            this.fileName = ''
        },
        removeItem: function (index) {
            this.paymentItems.splice(index, 1)
        },
        validationForm() {

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    if (router.currentRoute.params.id) {
                        formData.append('id', router.currentRoute.params.id)
                    }

                    if(this.document.InvoiceFile){
                        formData.append('InvoiceFileId', this.document.InvoiceFile.Id)
                    }

                    formData.append('Type', this.documentType)
                    formData.append('RecordType', this.document.RecordType)
                    formData.append('Category', this.documentCategory)
                    formData.append('NonTaxable', this.categoryNonTaxable ? 1 : 0)

                    formData.append('VendorId', this.document.VendorId)
                    formData.append('Currency', this.document.Currency)
                    formData.append('IsInvoice', this.document.IsInvoice ? 1 : 0)
                    formData.append('InvoiceNumber', this.document.InvoiceNumber)
                    formData.append('InvoiceDate', this.document.InvoiceDate)
                    formData.append('Description', this.document.Description)
                    formData.append('InternalComments', this.document.InternalComments)
                    formData.append('Discount', this.discountValue)
                    formData.append('DiscountAmount', this.discountAmount);
                    formData.append('DiscountPercent', this.discountMode === 'percent' ? this.discountValue : 0);


                    formData.append('PaymentType', this.document.PaymentType)
                    formData.append('BillingDetails', this.documentBillingDetails)
                    formData.append('BillingAccountId', this.documentBillingAccountId)


                    formData.append('Amount', this.paymentItems.filter(item => !!item.UnitPrice && !!item.Quantity).reduce((acc, item) =>
                            (acc + item.UnitPrice * item.Quantity),
                        0))
                    formData.append('VatAmount', this.totalVAT)
                    formData.append('TotalAmount', this.finalTotal)


                    formData.append('invoice_document_ids', this.document.InvoiceDocuments.map(doc => doc.Id).join(","))
                    formData.append('invoice_document_types', this.document.InvoiceDocuments.map(doc => doc.Type).join(","))

                    for (let i = 0; i < this.paymentItems.length; i++) {
                        formData.append(`invoice_items[${i}][id]`, this.paymentItems[i].Id ?? 0)
                        formData.append(`invoice_items[${i}][Title]`, this.paymentItems[i].Label)
                        formData.append(`invoice_items[${i}][UnitPrice]`, this.paymentItems[i].UnitPrice)
                        formData.append(`invoice_items[${i}][Quantity]`, this.paymentItems[i].Quantity)
                        formData.append(`invoice_items[${i}][Amount]`, this.paymentItems[i].Quantity * this.paymentItems[i].UnitPrice)
                        formData.append(`invoice_items[${i}][VatAmount]`, this.paymentItems[i].VatPercent * this.itemSubTotal(this.paymentItems[i], i)/100)
                        formData.append(`invoice_items[${i}][VatPercent]`, this.paymentItems[i].VatPercent)

                        if(this.discountAmount){
                            formData.append(`invoice_items[${i}][DiscountAmount]`, this.discounts[i])
                        }

                        formData.append(`invoice_items[${i}][TotalAmount]`, (!!this.paymentItems[i].Quantity && !!this.paymentItems[i].UnitPrice) ? this.paymentItems[i].Quantity * this.paymentItems[i].UnitPrice * (1 + (this.paymentItems[i].VatPercent ?? 0) / 100) + "" : "0")
                        formData.append(`invoice_items[${i}][Currency]`, this.document.Currency)
                    }

                    if (this.document.Type == "1") {
                        formData.append('DueDate', this.document.DueDate)
                    }

                    if (this.document.Type == "2") {
                        if (this.document.invoice_team) {
                            formData.append('invoice_teams', this.document.invoice_teams.map(team => team.value).join(","))
                        }
                        if (this.document.invoice_employees) {
                            formData.append('invoice_employees', this.document.invoice_employees.map(employee => employee.value).join(","))
                        }
                    }

                    //Repeating logic

                    if (this.document.Repeating) {
                        formData.append('repeating', this.document.Repeating)
                        formData.append('repeat_for', this.document.RepeatFor)

                        if (
                            this.document.RepeatFor.value == '1' ||
                            this.document.RepeatFor.value == '4'
                        )
                            formData.append('repeat_date',this.document.InvoiceDate)
                        else if (this.document.RepeatFor == '2')
                            formData.append(
                                'repeat_weekly',
                                this.RepeatWorkdays.map(workday => workday.value).join(',')
                            )
                        else if (this.document.RepeatFor == '3') {
                            formData.append(
                                'repeat_monthly',
                                this.RepeatMonths.map(m => m.value).join()
                            )
                            formData.append(
                                'day_of_month',
                                this.RepeatMonthDays.value
                            )
                        }
                    }

                    this.$http.post(
                        'invoices', formData
                    ).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })

                        router.push({name: 'finances'})
                    })
                        .catch((err) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        },
        setup() {
            const vm = getCurrentInstance()
            console.log(vm)
            return {vm}
        },
        addItems() {
            this.paymentItems.push({
                Quantity: 1,
                UnitPrice: 0,
                Total: 0,
                TotalAmount: 0,
                VatPercent: 0,
                VatAmount: 0,
            })
        }
    },
    watch: {
        documentBillingAccountId: function (newVal) {
            if(newVal === -1){
                this.documentBillingDetails = '';
            }else{
                let matchingRecord = this.ibansFull.find(iban => iban.Id === newVal);

                let defaultMessage = this.i18nT(`IBAN:`)+' '+ matchingRecord.Iban;
                defaultMessage += matchingRecord.Bic ? '\n'+this.i18nT(`BIC:`) +' '+ matchingRecord.Bic : '';
                defaultMessage += matchingRecord.BankName ? '\n'+this.i18nT(`Bank:`)+' '+matchingRecord.Bank : '';
                defaultMessage += matchingRecord.BankCode ? '\n'+this.i18nT(`Bank Code:`)+' '+matchingRecord.BankCode : '';

                this.documentBillingDetails = defaultMessage;
            }
        },
        documentType: {
            handler: function (newDocType, oldDocType) {
                if(oldDocType != newDocType){
                    if (newDocType == 1) {
                        this.$http.get(
                            `invoices/categories?type=1`
                        ).then(({data}) => {

                            let filteredData = data.data.filter(category => category.RecordType != 1 && category.RecordType != 3);

                            this.paymentCategoryList = filteredData.map(category => {
                                return {
                                    value: category.Id,
                                    text: category.Label,
                                    fullData: category
                                }
                            })

                            this.paymentCategoryList.unshift({
                                value: null, text: this.i18nT(`Please select a category`)
                            })
                        })
                    } else if (newDocType == 2) {
                        this.$http.get(
                            `invoices/categories?type=2`
                        ).then(({data}) => {

                            let filteredData = data.data.filter(category => category.RecordType != 3);
                            this.paymentCategoryList = filteredData.map(category => {
                                return {
                                    value: category.Id,
                                    text: category.Label,
                                    fullData: category
                                }
                            })

                            this.paymentCategoryList.unshift({
                                value: null, text: this.i18nT(`Please select a category`)
                            })
                        })
                    }
                }

            }
        },

        file: function (val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        paymentItems: {
            deep: true,
            handler: function (val) {
                this.paymentItems = this.updateTotals(val)
                this.updateTotalVat();
                this.calcActualDiscount();
            }
        },
        discountValue: function () {
            this.calcActualDiscount();
        },
        discountAmount: function () {
            this.paymentItems = this.updateTotals(this.paymentItems)
            this.updateTotalVat();
        },
        repeatMonthlyOptions: function (val){
            if(val[0].value !== '99') {
                let everyMonth = {...val[12]};
                this.repeatMonthlyOptions.splice(12, 1);
                this.repeatMonthlyOptions.unshift(everyMonth);
            }
        },
        dropzonePopulateFromResp(newVal) {
            if(newVal && this.tempInvoice){
                this.postProcessInvoice(this.tempInvoice)
            }else if (!newVal && this.originalInvoice){
                this.postProcessInvoice(this.originalInvoice)
            }
        },
    },
}
</script>

<style lang="scss">



.discountIcon {
    position: relative;
    margin: 0 auto;
    margin-top: calc(50% - 1px);
}


.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}
</style>
