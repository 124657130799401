<template>
    <div>
        <validation-observer ref="vendorRules">
            <b-form>
                <b-row>
                    <b-col cols="12" md="12">
                        <b-card no-body>
                            <b-card-header>
                                <h4 class="card-title">{{ i18nT(`Client / Vendor`) }}</h4>
                                <b-dropdown
                                    variant="link"
                                    toggle-class="p-0"
                                    no-caret
                                    right
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                        />
                                    </template>
                                    <b-dropdown-item @click="createOrUpdateVendor()">
                                        <span class="align-middle ml-50">{{ i18nT(`Save`) }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="hasRouteId" @click="onDelete()">
                                        <span class="align-middle ml-50" style="color: red">{{ i18nT(`Delete`) }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-card-header>
                            <b-card-body>
                                <b-row class="border-bottom pb-2">
                                    <!-- Field: Username -->
                                    <b-col cols="12" md="3">
                                        <h5>{{ i18nT(`Main information`) }}</h5>
                                    </b-col>

                                    <b-col cols="12" md="9">

                                        <!-- VENDOR TYPE -->
                                        <b-row>

                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Relation`)" label-for="vendorType"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Relation`)"
                                                        rules="required"

                                                    >
                                                        <v-select
                                                            v-model="vendor.Type"
                                                            :options="vendorTypes"
                                                            label="text"
                                                            id="vendorType"
                                                            :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="6" class="pt-radios mb-2">

                                                <b-form-radio-group
                                                    v-model="vendor.CompanyType"
                                                    :options="compTypes"
                                                    aria-controls="ex-disabled-readonly"
                                                    class="d-inline-block"
                                                >
                                                </b-form-radio-group>

                                            </b-col>
                                        </b-row>


                                        <!-- VENDOR MAIN DETAILS -->
                                        <b-row v-if="vendor.CompanyType == 'company'">
                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Company name`)" label-for="vendorLabel"
                                                              class="required">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Company name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            id="vendorLabel"
                                                            v-model="vendor.Label"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Legal representative`)"
                                                              label-for="vendorRepresent">
                                                    <b-form-input
                                                        id="vendorRepresent"
                                                        v-model="vendor.LegalRepresentative"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Company identification number`)" label-for="vendorUic">
                                                    <b-form-input
                                                        id="vendorUic"
                                                        v-model="vendor.Number"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`VAT number`)" label-for="vendorVatNumber">
                                                    <div class="d-flex">
                                                        <b-form-input
                                                            class="corners-straight-right"
                                                            :placeholder="i18nT(`e.g. FR123456789`)"
                                                            @keyup.enter="lookupVendor()"
                                                            v-model="vendorVatNumber"
                                                        />
                                                        <div class="semi-button cursor-pointer"
                                                             v-b-tooltip="i18nT(`Lookup company details by VAT number`)"
                                                             @click="lookupVendor()">
                                                            <font-awesome-icon
                                                                class="d-block text-white ml-2"
                                                                :icon="['fa', 'search']"></font-awesome-icon>
                                                        </div>
                                                    </div>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Company phone`)" label-for="vendorPhone">
                                                    <b-form-input
                                                        id="vendorPhone"
                                                        v-model="vendor.BusinessPhone"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="type"
                                                    rules="email"
                                                >
                                                    <b-form-group :label="i18nT(`Company email`)"
                                                                  label-for="vendorEmail">
                                                        <b-form-input
                                                            id="vendorEmail"
                                                            v-model="vendor.BusinessEmail"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                    </b-form-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-col>


                                        </b-row>


                                        <!-- VENDOR PERSON DETAILS -->
                                        <b-row v-if="vendor.CompanyType == 'person'">
                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Full name`)" label-for="vendorLabel"
                                                              class="required">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Full name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            id="vendorLabel"
                                                            v-model="vendor.Label"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Personal identification number`)" label-for="vendorUic">
                                                    <b-form-input
                                                        id="vendorUic"
                                                        v-model="vendor.Number"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Contact phone`)" label-for="vendorPhone">
                                                    <b-form-input
                                                        id="vendorPhone"
                                                        v-model="vendor.BusinessPhone"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="type"
                                                    rules="email"
                                                >
                                                    <b-form-group :label="i18nT(`Contact email`)"
                                                                  label-for="vendorEmail">
                                                        <b-form-input
                                                            id="vendorEmail"
                                                            v-model="vendor.BusinessEmail"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                    </b-form-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-col>

                                        </b-row>
                                    </b-col>

                                </b-row>

                                <!-- VENDOR ADDRESS -->
                                <b-row class="border-bottom py-2">
                                    <b-col cols="12" md="3">
                                        <h5 class="mt-2">{{ i18nT(`Address`) }}</h5>
                                    </b-col>

                                    <b-col cols="12" md="9" class="pt-2">

                                        <b-row>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`Address`)" label-for="vendorAddress">
                                                    <b-form-input
                                                        id="vendorAddress"
                                                        v-model="vendor.Address"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group :label="i18nT(`City`)" label-for="vendorCity">
                                                    <b-form-input
                                                        id="vendorCity"
                                                        v-model="vendor.City"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="4">
                                                <b-form-group :label="i18nT(`ZIP`)" label-for="vendorZIP">
                                                    <b-form-input
                                                        id="vendorZIP"
                                                        v-model="vendor.Zip"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="4">
                                                <b-form-group :label="i18nT(`State`)" label-for="vendorState">
                                                    <b-form-input
                                                        id="vendorState"
                                                        v-model="vendor.State"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Country`)" label-for="vendorCountry">
                                                    <v-select
                                                        v-model="vendor.Country"
                                                        :options="countries"
                                                        label="text"
                                                        id="vendorCountry"
                                                        :reduce="(e) => e.value == '' ? '' : e.value"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom py-2">
                                    <!-- Field: Username -->
                                    <b-col cols="12" md="3">
                                        <h5 class="mt-2">{{ i18nT(`Description`) }}</h5>
                                    </b-col>
                                    <b-col cols="12" md="9" class="pt-2">
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Description`)"
                                                    label-for="vendorDescription"
                                                >
                                                    <b-form-textarea
                                                        id="vendorDescription"
                                                        rows="3"
                                                        v-model="vendor.Description"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- VENDOR CONTACTS -->
                                <b-row class="border-bottom pt-2">

                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{ i18nT(`Contacts`) }}
                                        </h5>
                                        <p class="mt-1">
                                            {{ i18nT(`List of important persons that have a relation to this vendor and could be referenced for invoicing or business communication.`) }}
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <div
                                            v-for="(item, index) in vendor.Contacts"
                                            :key="index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="12"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Name`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Name`)"
                                                            v-model="item.Name"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="12"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Relationship`)"
                                                        label-for="Relationship"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Relationship`)"
                                                            v-model="item.Relationship"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="6"
                                                    md="6"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`E-Mail`)"
                                                        label-for="E-Mail"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="type"
                                                            rules="email"
                                                        >
                                                            <b-form-input
                                                                :placeholder="i18nT(`email@domain.com`)"
                                                                v-model="item.Mail"
                                                                :state="errors.length > 0 ? false : null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="6"
                                                    md="6"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Phone`)"
                                                        label-for="Phone"
                                                    >
                                                        <MazPhoneNumberInput
                                                            :key="phoneLoaded+index"
                                                            default-country-code="FR"
                                                            :placeholder="i18nT(`Phone`)"
                                                            v-model="item.Phone"
                                                            :translations="{
                                                              countrySelectorLabel: i18nT(`Country code`),
                                                              countrySelectorError: i18nT(`Select a country`),
                                                              phoneNumberLabel: i18nT(`Phone number`),
                                                              example: i18nT(`Example:`),
                                                            }"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeContact(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon"/>
                                                    {{ i18nT(`Remove`) }}
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addContacts"
                                            >
                                                <feather-icon icon="PlusCircleIcon"/>
                                                {{ i18nT(`Add more`) }}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <div style="flex: 1"/>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        @click.prevent="createOrUpdateVendor"
                                    >
                                        {{ i18nT(`Save`) }}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<style scoped>
.pt-radios {
    padding-top: 32px;
}

</style>

<script>
import {
    BButton,
    // BButtonGroup,
    // BAlert,
    // BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormRadioGroup,
    //BFormCheckbox,
    // BFormCheckboxGroup,
    BFormTextarea,
    // BFormDatepicker,
    BCardBody,
    // BFormSelect,
    VBModal,
    VBTooltip,
    // BTabs,
    // BTab,
    // BInputGroup,
    // BInputGroupText,
    // BFormRadio,
    // BListGroupItem,
    BCardHeader,
    BDropdown,
    BDropdownItem
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import {dictToSelectArray} from '@core/utils/utils'
//import debounce from 'lodash/debounce';


// import draggable from "vuedraggable";
// import DatePicker from '@core/components/DatePicker.vue'

export default {
    components: {
        BButton,
        // BButtonGroup,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardBody,
        vSelect,
        BFormTextarea,
        // BFormDatepicker,
        // BFormSelect,
        // BAlert,
        // BBadge,
        // BFormCheckbox,
        // BFormCheckboxGroup,
        // BTabs,
        // BTab,
        ValidationProvider,
        ValidationObserver,
        // BInputGroup,
        // BInputGroupText,
        // BFormRadio,
        // BListGroupItem,
        // draggable,
        BCardHeader,
        BDropdown,
        BDropdownItem,
        BFormRadioGroup,
        // DatePicker
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
        "b-tooltip": VBTooltip
    },
    data() {
        return {
            required,
            email,

            vendorVatNumber: '',

            vendor: {
                Contacts: [],
                Repeating: false,
                CompanyType: 'company',
            },
            phoneLoaded: 'notLoaded',
            vendorTypes: [],
            countries: [],
            compTypes: [
                {text: this.i18nT(`Company`), value: 'company'},
                {text: this.i18nT(`Private person`), value: 'person'},
            ]
        };
    },
    async created() {
        this.$http.get(`system/vendorTypes`).then(({data}) => {
            this.vendorTypes.push(...dictToSelectArray(data.data))
        })

        this.$http.get(`system/countries`).then(({data}) => {
            this.countries = dictToSelectArray(data.data)
        })

        const id = router.currentRoute.params.id;
        if (id) {
            this.$http.get(`vendors?id=${id}`).then(({data}) => {
                this.vendor = data.data;
                this.vendorVatNumber = this.vendor.VatNumber
                this.phoneLoaded = 'loaded'
            });
        }
    },
    computed: {
        hasRouteId() {
            return router && router.currentRoute.params.id
        }
    },
    props: {
        modalMode: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        resetData(){
            this.vendor = {
                Contacts: [],
                Repeating: false,
                CompanyType: 'company',
            }
        },
        addContacts() {
            this.vendor.Contacts.push({})
        },
        removeContact(index) {
            this.vendor.Contacts.splice(index, 1)
        },
        createOrUpdateVendor() {
            const id = router.currentRoute.params.id;

            this.$refs.vendorRules.validate().then((success) => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData();
                    if (id && id !== "new") formData.append("id", id);

                    for (const prop in this.vendor) {
                        if(prop !== 'Id'){
                            formData.append(prop, this.vendor[prop])
                        }
                    }

                    formData.append('VatNumber', this.vendorVatNumber);

                    formData.append('repeat_for', parseInt(this.vendor.VenderoRepeated))


                    for (let i = 0; i < this.vendor.Contacts.length; i++) {
                        if (this.vendor.Contacts[i].Id) {
                            formData.append(`contact_id[${i}]`, this.vendor.Contacts[i].Id)
                        }
                        formData.append(`contact_name[${i}]`, this.vendor.Contacts[i].Name)
                        formData.append(`contact_relationship[${i}]`, this.vendor.Contacts[i].Relationship)
                        formData.append(`contact_mail[${i}]`, this.vendor.Contacts[i].Mail)
                        formData.append(`contact_phone[${i}]`, this.vendor.Contacts[i].Phone)
                    }

                    this.$http
                        .post("vendors", formData)
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: this.successMessage(res),
                                    icon: "InfoIcon",
                                    variant: "success",
                                },
                            });

                            if(this.modalMode){
                                this.$emit('vendorAdded', res.data.data)
                                this.resetData();

                            } else {
                                router.push({
                                    name: "vendors",
                                });
                            }

                        })
                        .catch((err) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: this.errorMessage(err),
                                    icon: "InfoIcon",
                                    variant: "danger",
                                },
                            });
                        });
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: this.i18nT(`Please fill all required fields`),
                            icon: "InfoIcon",
                            variant: "danger",
                        },
                    });
                }
            });
        },
        onDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this entry?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirm`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    const id = router.currentRoute.params.id;
                    this.$http.delete(`vendors?id=${id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The vendor has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        router.push({
                            name: "vendors",
                        });
                    })
                }
            })
        },
        lookupVendor() {
            let self = this;
            this.$http.get(`invoices/lookUpVendorByVat?vatNumber=${this.vendorVatNumber}`).then(({data}) => {

                let mergedVendor = {};

                for (const prop in data.data) {
                    // Only update properties that are not 'Id', are available in data.data, and are empty in this.vendor
                    if(prop !== 'Id' && data.data[prop] && self.empty(self.vendor[prop])) {
                       mergedVendor[prop] = data.data[prop];
                    } else if (!self.empty(self.vendor[prop])) {
                       mergedVendor[prop] = self.vendor[prop];
                    }
                }

                this.vendor = {...mergedVendor};

                this.phoneLoaded = 'loaded';
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })

        }
    },
    watch: {
        /*
        vendorVatNumber: debounce(function() {
            this.lookupVendor();
        }, 1000)
        */
    }
};
</script>
